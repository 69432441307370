<template>
  <div class="mt-1 mx-3">
    <pdf-create></pdf-create>
  </div>
</template>

<script>
import PdfCreate from "@/components/profile/lisacomunica/pdf/create.vue";
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    PdfCreate,
  },
  created() {
    this.notifyQueue({
      text: "Pdf",
      path: "lisacomunica_template",
      tab: "Pdf",
      level: 3,
    });
    this.notifyQueue({
      text: "Inserisci template pdf",
      path: "pdf.create",
      level: 4,
    });
  },
};
</script>
